.dragHandlerContainer {
    /*animation: wobble 1s ease infinite;*/
    background-color: #00ffde21;
}

.dragHandlerHandleContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dropTarget{
    background-color: #00a09b;
}

@keyframes wobble {
    0%, 100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-1px) rotate(-0.5deg);
        transform: translateX(-1px) rotate(-0.5deg);
    }

    30% {
        -webkit-transform: translateX(calc(1px / 2)) rotate(0.5deg);
        transform: translateX(calc(1px / 2)) rotate(0.5deg);
    }

    45% {
        -webkit-transform: translateX(calc(-1px / 2)) rotate(calc(-0.5deg / 1.8));
        transform: translateX(calc(-1px / 2)) rotate(calc(-0.5deg / 1.8));
    }

    60% {
        -webkit-transform: translateX(calc(1px / 3.3)) rotate(calc(0.5deg / 3));
        transform: translateX(calc(1px / 3.3)) rotate(calc(0.5deg / 3));
    }

    75% {
        -webkit-transform: translateX(calc(-1px / 5.5)) rotate(calc(-0.5deg / 5));
        transform: translateX(calc(-1px / 5.5)) rotate(calc(-0.5deg / 5));
    }
}


@keyframes bounce {
    0% {
        transform: translate(0px, 0px);
        animation-timing-function:ease-in-out
    }

    20% {
        transform: translate(0px, 12px);
        animation-timing-function:ease-in-out
    }

    100% {
        transform: translate(0px, 0px);
        animation-timing-function:ease-in-out
    }
}

.fade-in-image { animation: fadeIn 300ms; }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}


.fade-in-image2 { animation: fadeIn2 2000ms; }

@keyframes fadeIn2 {
    0% { opacity: 0; }
    100% { opacity: 0.3; }
}

.tabSlide{
    animation-duration: 1000ms;
    animation-name: tabsliding;
    animation-fill-mode: forwards;
}

@keyframes tabsliding {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(10deg); }
}


.scrollbaranimatein{
    animation-duration: 100ms;
    animation-name: slidein;
    animation-fill-mode: forwards;
}

.scrollbaranimateout{
    animation-duration: 800ms;
    animation-name: slideout;
    animation-fill-mode: forwards;
}

.fastscrollbaritemanimateout{
    animation-duration: 200ms;
    animation-name: slideout;
    animation-fill-mode: forwards;
}

.chatmenuanimatein{
    animation-duration: 100ms;
    animation-name: slidein-translate;
    animation-fill-mode: forwards;
}

.chatmenuanimateout{
    animation-duration: 400ms;
    animation-name: slideout-translate;
    animation-fill-mode: forwards;
}

.chatmenuanimateoutdirect{
    animation-duration: 0ms;
    animation-name: slideout;
    animation-fill-mode: forwards;
}

@keyframes slidein-translate {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
}

@keyframes slideout-translate {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}


@keyframes slidein {
    0% {
        transform: scale(0, 1);
        transform-origin: right;
        animation-timing-function:ease-in-out
    }

    100% {
        transform: scale(1, 1);
        transform-origin: right;
        animation-timing-function:ease-in-out
    }
}

@keyframes slideout {
    0% {
        transform: scale(1, 1);
        transform-origin: var(--transform-origin);
        animation-timing-function:ease-in-out
    }

    100% {
        transform: scale(0, 1);
        transform-origin: var(--transform-origin);
        animation-timing-function:ease-in-out
    }
}

.toggleRotate{
    animation: toggleRotater 0.3s linear;
}

@keyframes toggleRotater {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
}


.spinner {
    animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
    dash 1.4s ease-in-out infinite,
    colors 5s ease-in-out infinite;
}

@keyframes colors {
    0% { stroke: #4285F4; }
    25% { stroke: #DE3E35; }
    50% { stroke: #F7C223; }
    75% { stroke: #1B9A59; }
    100% { stroke: #4285F4; }
}

@keyframes dash {
    0% { stroke-dashoffset: 187; }
    50% {
        stroke-dashoffset: 46.75;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform:rotate(450deg);
    }
}

.scoretimeBlinker {
    animation: blinker 2s ease-in-out infinite;
    color:var(--text-color-matchstatus);
    font-size: var(--text-size-xlarge);

}

@keyframes blinker {
    20% {
        opacity: 0;
    }
}




@keyframes playerFlip {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(180deg); }
}

@keyframes playerFlip2 {
    0% { transform: rotateY(180deg); }
    100% { transform: rotateY(0deg); }
}

@keyframes toast_fadein {
    0% {bottom: 0; opacity: 0;}
    100% {bottom: 30px; opacity: 1;}
}
@keyframes toast_fadeout {
    0% {bottom: 30px; opacity: 1;}
    100% {bottom: 0; opacity: 0;}
}


.bouncePanelAnimation {
    animation-duration: 200ms;
    animation-name: bounce;
}

@keyframes bounce {
    0% {
        transform: translate(0px, 0px);
        animation-timing-function:ease-in-out
    }

    50% {
        transform: translate(0px, 12px);
        animation-timing-function:ease-in-out
    }

    100% {
        transform: translate(0px, 0px);
        animation-timing-function:ease-in-out
    }
}

.loaderContainer{
    border-radius: 10px;
    color: #fff;
    text-align: center;
    padding: 8px;
    padding-left:16px;
    padding-right:16px;
    position: fixed;
    z-index: 1000000;
    bottom: 50px;
    font-size: 17px;
    left: 50%;
    transform: translateX(-50%);
    width:80%;
    color:var(--text-color-matchstatus);
    background-color:#adadad1c;
    font-size:14px;

}
.loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;

    background: var(--listItemFavoriteOffColor);
    overflow: hidden;
}
.loader::after {
    content: '';
    box-sizing: border-box;
    width: 0;
    height: 4.8px;
    background: var(--match-votebutton-selected-background-color);
    position: absolute;
    top: 0;
    left: 0;
    animation: animFw 1.0s ease-in;
    animation-fill-mode: forwards;
}

@keyframes animFw {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}



.main-fader2 {
    animation: loaderrotate 1.0s linear;
    animation-iteration-count: infinite;
}

.pullToRefreshContainer path {
    animation-duration: 0.6s;
    animation-name: pulse;
    animation-iteration-count: 1;
    color: #2686a3;
}
.path-7 {
     animation-delay: -1s
 }
.path-6 {
     animation-delay: -.875s
 }
.path-5 {
     animation-delay: -.75s
 }
.path-4 {
     animation-delay: -.625s
 }
.path-3 {
     animation-delay: -.5s
 }
.path-2 {
     animation-delay: -.375s
 }
.path-1 {
     animation-delay: -.25s
 }
.path-0 {
     animation-delay: -.125s
 }

@keyframes pulse {
    0%     { opacity: .1; }
    100%   { opacity: .1; }
}

@keyframes loaderrotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.highlightanimated {
    border-radius: 3px;
    animation: highlight 1000ms ease-out;
}

@keyframes highlight {
    from {
        background-color: #4fc04b;
    }
}
