.topBar{
    display: flex;
    direction: var(--direction);
    width: 100%;
    background-color: var(--topbar-background-color);
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    z-index: 4;
    position: relative;
    justify-content: space-between;
}


.topBarTopContainer{
    display: flex;
    height: 50px;
    width: 100%;
    flex-direction: row;
    align-content: center;
    z-index: 4;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.topBarTopTitleContainer{
    height:30px!important;
    background-color: var(--topbar-titlebackground-color);
    z-index: 4;
    width:100%;
    font-weight: bold;
    font-variant: all-small-caps;
    font-size: var(--text-size-xlarge);
    justify-content: center!important;
}

.topBarTopTitleContainer img{
    margin-inline-end: 8px;
}

.topLogoContainer{
    margin-inline-start: 5px;
}

.topLogo{
    background: url("../images/Football_Mania_Icon_c.png");

    height: 33px;
    width: 33px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0px;
    background-size: 33px;
}

.topLogoIOS{
    -webkit-mask:var(--icon-back-arrow) no-repeat 50% 50%;;
    mask:var(--icon-back-arrow) no-repeat 50% 50%;;
    -webkit-mask-size: cover;
    margin-right:8px;
    mask-size: cover;
    background-size:contain;
    height:33px;
    width:33px;
    background-color: var(--icon-default-color);
}

.topBarSearchContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

input:focus{ border: none; }

textarea:focus, input:focus{
    outline: none;
}

.topBarSearchContainer input{
    width:100%;
    caret-color: var(--text-color-matchstatus);
    height: 30px;
    background: transparent;
    border: 0;
    border-style: none;
    border-radius:0px;
    color: var(--text-color);
    font-size: var(--text-size-xxlarge);
    margin-inline-end: 60px;
}

.topBarSearchResultsContainer{
    position: absolute;
    width:calc(100% - 35px);
    margin-inline-start: 30px;
    margin-top: 50px;
}

.topBarSearchResults{
    background-color: var(--topbar-search-result-background-color);
    max-height:400px;
    width:100%;
    position: absolute;
    overflow: scroll;
}

.topBarSearchResults .listViewMatchStatusMatch{
    font-size: var(--text-size-large);
    font-weight:normal;
}

.topBarSearchResults .listViewItem{

    font-weight:normal;
}

.topBarSearchResultItem{
    height:50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.topBarSearchResultItem img{
    padding:4px;
    position: relative;
}