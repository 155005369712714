@property --p{
    syntax: '<number>';
    inherits: true;
    initial-value: 1;
}

.pie {
    --p:20;      /* the percentage */
    --b:5px;    /* the thickness */
    --c:; /* the color */
    --w:90px;   /* the size*/
    word-break: break-word;
    width:var(--w);
    aspect-ratio:1/1;
    position:relative;
    display:inline-grid;
    margin:5px;
    place-content:center;
    font-size:var(--text-size-large);
    text-align: center;
}
.pie:before,
.pie:after {
    content:"";
    position:absolute;
    border-radius:50%;
}
.pie:before {
    inset:0;
    background:
            radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
            conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.pie:after {
    inset:calc(50% - var(--b)/2);
    background:var(--c);
    transform:rotate(calc(var(--p)*3.6deg - 90deg)) translate(calc(var(--w)/2 - 50%));
}
.animate {
    animation:p 1s .3s both;
}
.no-round:before {
    background-size:0 0,auto;
    background-color: #8f8f8f2b;
}
.no-round:after {
    content:none;
}
@keyframes p{
    from{--p:0;}
}