*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    overscroll-behavior: none;
}

a:link {
    text-decoration: none;
    color: var(--text-color);
}

a:link, a:visited {
    color: var(--text-color);
}

a:hover, a:active {
    color: var(--text-color);
}

/*a:focus {*/
/*    outline: none;*/
/*    border:1px solid var(--text-color-matchstatus);*/
/*    border-radius: 4px;*/
/*    background: rgba(148, 255, 148, 0.19);*/
/*}*/

/*button:focus {*/
/*    outline: none;*/
/*    border:1px solid var(--text-color-matchstatus);*/
/*    border-radius: 4px;*/
/*    background: rgba(148, 255, 148, 0.19);*/
/*}*/

div {
    -webkit-tap-highlight-color: rgba(0, 208, 255, 0.33);
}

body{
    font-size:var(--text-size-normal);
    font-family:Verdana;
    overscroll-behavior-y: none;
    -webkit-overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;

    overflow: hidden;
    color:var(--text-color);
    /*font-family:Arial, Helvetica, sans-serif;*/
    background:repeat-x var(--background-color);
    -webkit-touch-callout: none !important;
    /*font-family:Lucida Grande,Arial,Helvetica,sans-serif;*/
}

*:not(input):not(textarea) {
    -webkit-user-select: none; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

a { -webkit-user-select: none !important; }

@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition-duration: 0s !important;
    }
}

img[src=""] {
    content:url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==");
}

.noscrollbar::-webkit-scrollbar{
    display: none;
}

.noscrollbar::-webkit-scrollbar {
    width: 0 !important
}
/*.BPageTemplate{*/
/*    position: fixed;*/
/*}*/

.error{
    z-index: 2;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: var(--text-size-xxxlarge);
    line-height: 39px;
    text-align: center;
}

.bouncePanel{
    -webkit-overflow-scrolling: touch;
}

.text-small{
    font-size: var(--text-size-small);
}

.greentext{
    color:var(--text-color-matchstatus);
}

.noDataTapText{
    font-size: var(--text-size-large);
    line-height: 20px;
}

.flexHorizontalAlign{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    direction: var(--direction);
}

.flexVerticalAlign{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    direction: var(--direction);
}

.flexAlignStart{
    justify-content: flex-start;
}

.flexAlignEnd{
    justify-content: flex-end;
}

.noDirection{
    direction: unset;
}


.tableStandings{
    width:100%;
    font-variant: none;
    font-size: var(--text-size-normal);
    text-align: center;
    border-spacing: 1px;
    direction: var(--direction);
}

.tableStandings img{
    width:36px;
    display: flex;
}

.tableImageSmall img{
    width:28px;
}

.tableStandings th{
    border-spacing: 0px;
    font-size: var(--text-size-large);
    padding:4px;
    background-color: var(--listHeaderBackground);
    font-variant: all-small-caps;
    font-weight: normal;
}

.tableStandings tr{
    height:44px;
    cursor: pointer;
}

.standingsLive{
    color:var(--text-color-matchstatus);
}

.tableFooter {
    height:50px;
    width:100%;
    position:absolute;
    bottom:0;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    font-size: var(--text-size-xsmall);
    background-color: var(--topbar-background-color);
}

.button{
    height: 39px;
    padding-left:10px;
    padding-right:10px;
    background-color: #808080;
    color: white;
    border-radius: 4px;
    font-size: var(--text-size-xlarge);
    font-variant: all-small-caps;
    line-height: 33px;
    text-align:center;
}



.addFavoriteModalTitle{
    width: 90%;
    font-size: var(--text-size-xxlarge);
    font-weight: bold;
    font-variant: all-small-caps;
    line-height: 23px;
    text-align: center;
    margin-bottom: 10px;
}


.snackbar {
    display: none;
    border-radius: 10px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 8px;
    padding-left:16px;
    padding-right:16px;
    position: fixed;
    z-index: 1000000;
    bottom: 30px;
    font-size: 17px;
    left: 50%;
    transform: translateX(-50%);
}

.snackbarshow {
    display: inline;
    animation: toast_fadein 0.5s, toast_fadeout 0.5s 2.5s;
    animation-fill-mode: forwards;
}


.stickybanner {
    height:50px;
    width:100%;
    position:absolute;
    overflow: hidden;
    z-index:999999;
    bottom:0;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    font-size: var(--text-size-xsmall);
    background-color: #ffffff00;
}

.RatingBad{
    color:#d32f2f;
}

.RatingOkaish{
    color:#f57c00;
}

.RatingAvarage{
    color: #acc53f;
}

.RatingGood{
    color:#388e3c;
}

.RatingExcellent{
    color: #aa02a2;
}


input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 26px;
    height: 26px;
    border: 0.15em solid #7c7c7c70;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    background-color: #0000002b;
}

input[type="checkbox"]::before {
    content: "";
    width: 26px;
    height: 26px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
    background-color: var(--tab-indicator-color);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

/*input[type="checkbox"]:focus {*/
/*    outline: max(2px, 0.15em) solid currentColor;*/
/*    outline-offset: max(2px, 0.15em);*/
/*}*/

input[type="checkbox"]:disabled {
    cursor: not-allowed;
}
.pullToRefreshContainer{
    width: 40px;
    height: 40px;
    position: absolute;
    top: -45px;
    left: calc(50% - 20px);
}