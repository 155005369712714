.ChatScrollerContainer .BDataGridTileListRender{
    /*margin-top: auto;*/
}

.ChatScrollerContainer .listViewMainContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.ChatMessageMainContainer{
    display:flex;
    width: 100%;
    flex-direction: var(--flex-direction);
}

.ChatMessageContainer{

    margin:4px;
    /*margin-right:0;*/
    background-color: var(--listHeaderBackground);
    border-radius:10px;
    display: flex;
    flex-direction: var(--flex-direction);
    width: fit-content;
    min-width: 200px;
    padding-inline-end: 10px;
    /*direction: var(--direction);*/
}

.ChatMessageContentContainer{
    width:100%;
}

.ChatMessageImage{
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 20px;
}

.ChatMessageName{
    font-weight:bold;
    margin-bottom:8px;
    color: var(--tab-indicator-color);
}

.ChatMessageBottom{

    width: 100%;
    text-align: end;
    margin-top:4px;
    font-size: var(--text-size-xsmall);
    color:var(--text-color-tertary);
    padding-inline-end: 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ChatMessageContentContainer{
    margin: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    direction: var(--direction);
}

.ChatMessageTopContainer{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.ChatMessageRating{
    font-size:10px;
    margin-left: 10px;
    margin-top:-10px;
}

.ChatRatingSuperBad{
    color: #860d0d;
}

.ChatRatingBad{
    color:#d32f2f;
}

.MChatRatingGood{
    color: #acc53f;
}

.ChatRatingBetter{
    color:#388e3c;
}

.ChatRatingExcellent{
    color: #aa02a2;
}

.ChatInput{
    width: 70%;
    height: 40px;
    border: 0px solid;
    border-bottom: 1px solid;
    border-bottom-color: var(--tab-indicator-color);
    color: var(--text-color);
    margin-inline-start: 10px;
    margin-bottom:10px;
    background-color: #10192600;
    padding-inline-start: 10px;
    font-size: 18px;
}

.ChatInput textarea:focus, input:focus{
    border-bottom: 1px solid;
    border-bottom-color: var(--tab-indicator-color);
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    margin-inline-start: 11%;
    border-left: 20px solid;
    border-left-color: var(--tab-indicator-color);
}

.ChatMenuPopup{
    width:60px;
    height:150px;
    position: absolute;
    right:0;
    transform: scale(0, 1);
    transform-origin: right;
}

.ChatMessageContainerBlur{
    filter: blur(3px);
}

.menuchaticon{
    width:56px;
    height:56px;
    margin-top:2px;
    margin-bottom:10px;
    margin-left:2px;
}

.adminmenuusername{
    height:20px;
    width:100%;
    height: 20px;
    width: 100%;
    margin-top: 8px;
    font-size: 15px;
}

.adminmenumessage{
    margin-top: 20px;
    font-size: 16px;
}

.adminmenuitem{
    width: 90%;
    margin-top: 13px;
    height: 40px;
    background-color: #4a79b4;
    border: 1px solid blue;
    border-radius: 10px;
    font-size: 20px;
    line-height: 36px;
    font-variant: all-small-caps;
    margin-left: 5%;
}