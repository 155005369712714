.Modal {
    position: fixed;
    top: 0;
    display:block!important;
    z-index:99;
    margin: auto;
    left: 0;
    right: 0;
}

.ModalCover {
    position: fixed;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    background-color: #222222;
    top: 0;
    z-index: 100;
}

.ModalMain {
    position: relative;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 100;
    -webkit-box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 0px 12px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.75);
}

.ModelInnerBorder {
    border-radius: 3px;
}

.ModelContents {
    padding: 15px;
    padding-top: 1px;
}

.ModalTitle {
    font-weight: bold;
    font-size: 16px;
    color: #807373;
    width: 100%;
    margin-left: 13px;
    margin-top: 6px;
    display: inline-block;
}

.ModalClose {
    text-transform: uppercase;
    top: 5px;
    position: absolute;
    right: 10px;
    font-weight: bold;
    font-size: 1.3em;
    color: #888888;
    font-family: verdana;
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 15px;
    cursor: pointer;
}

.loader-overlay{
    padding-top:20px;
    text-align:center;
    opacity:0.5;top:0;
    position:absolute;
    background-color:#ffffff;
    width:100%;
    height:100%;
}



.homodal{
    overflow:hidden;
}

.homodal-mainpanel-wrapper{
    position: relative;
    z-index:400;
    display: flex;
}

.homodal-scrollpanel{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 88888;
}


.homodal-bg{
    position:absolute;
    top:0;
    width:100%;
    height:100%;
    opacity:0.1;
    background-color:black;
    z-index:100;
}

.homodal-bg-ani{
    -webkit-animation-name: homodal-bg-ani;
    -webkit-animation-duration: 300ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    z-index:100;
}

@-webkit-keyframes homodal-bg-ani {
    0% {
        opacity:0;
        z-index:999999;
    }
    100% {
        opacity:0.2;
        background-color: black;
        z-index:999999;
    }
}

.homodal-bg-closing{
    opacity:0;
    transition: opacity 0.1s ease-in-out;
}

.homodal-mainpanel{
    max-width: 80%;
    top: 0;
    z-index: 999999;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    border: 1px solid rgba(0, 255, 255, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    border-radius: 10px;
}

.homodal-margin{
    width: 100%;
    height: 100%;
    padding: 0px;
}

.homodal-mainpanel-ani{
    -webkit-animation-name: homodal-mainpanel-ani;
    -webkit-animation-duration: 150ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    z-index:999999;
}

@-webkit-keyframes homodal-mainpanel-ani {
    0% {
        opacity:0;
        transform: scaleX(0.4) scaleY(0.1);

    }
    100% {
        opacity:1;
        transform: scaleX(1) scaleY(1);
    }
}

.homodal-mainpanel-ani-close{
    -webkit-animation-name: homodal-mainpanel-ani-close;
    -webkit-animation-duration: 200ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    z-index:999999;
    animation-fill-mode:forwards;
}

@-webkit-keyframes homodal-mainpanel-ani-close {
    0% {
        opacity:1;
        transform: scaleX(1) scaleY(1);

    }
    100% {
        opacity:0;
        transform: scaleX(0.4) scaleY(0.1);
        display:none;
    }
}

.homodal-closeButton{
    background-color:var(--modal-close-button-color);
    width:50px;
    height:50px;
    position:absolute;top:-25px;right:-25px;
}

.homodal-contentsbg{
    position:absolute;
    top: 0;
    border-radius:10px;
    height:100%;
    width:100%;
    background-color: var(--text-color-matchstatus);
    z-index: -1;
    opacity:0.7;

}

.homodal-contents{
    background-color: var(--listItemBackground);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}


.homodal-contents-real{
    padding: 5px;
}

.homodal-main-text{
    margin-bottom:10px;
}

.homodal-sub-text{
    margin-bottom:10px;
    color: #76da76;
}

.homodal-button-container{
    width:100%;
    height:60px;
}

.homodal-button-small-text{

}


.homodal-button-container-part{
    float:left;
    height:100%;
    width:50%;
    text-align:center;
    padding: 5px;
}

.homodal-button-container-full{
    float:left;
    height:100%;
    width:100%;
    text-align:center;
    padding: 5px;
}

.homodal-button{
    width: 80%;
    height: 100%;
    background-color: #34d090;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid black;
    text-align:center;
    color:black;
    padding-top: 8px;
}

.modal-button-touch{
    -webkit-animation-name: modal-button-touch;
    -webkit-animation-duration: 300ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
}

.selectedCountriesContainer{
    display:flex;
}

.modal-country-reset-button{
    border-radius:6px;
    display:flex;
    font-size: var(--text-size-xlarge);
    justify-content: center;
    margin-inline-start: 5px;
    align-items: center;
    min-width:100px;
    background-color: var(--match-votebutton-background-color);
}

.modalSearchContainer input{
    margin-inline-end: 0px;
}

@-webkit-keyframes modal-button-touch {
    0% {
    }
    70% {
        background-color: white;
    }
    100% {
    }
}

.loader_wrapper{
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 100000;
}

.loader_container{
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-content: space-around;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.loader_text{
    z-index: 2;
    width:100%;
    text-align:center;
    margin-top:2px;
    font-size: 15px;
    font-variant: all-small-caps;
    color: #e8faff;
}

.loader_background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

.loader_spinner{
    margin-top:10px;
    z-index:2;
    /* bring your own prefixes */
    width:30px;
}

.loader_fade{
    background-color: rgb(0, 0, 0);
    z-index: 10;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0.0;
}

.containerLoader{
    margin-top:20px;
    margin-bottom:50px;
    text-align: center;
}

.containerLoaderTitle{
    float: left;
    width: 100%;
    text-align: center;
    font-variant: small-caps;
    font-size: 16px;
}

.containerLoaderImage{
    text-align: center;
    height: 12px;
    margin-right: 10px;
}

