:root {
    --text-size-xxxxlarge : 22px;
    --text-size-xxxlarge: 17px;
    --text-size-xxlarge: 16px;
    --text-size-xlarge: 15px;
    --text-size-large: 14px;
    --text-size-normal: 13px;
    --text-size-small:12px;
    --text-size-xsmall:11px;
    --text-size-empty-data-warning:20px;
    --text-size-match-header:28px;

}

html[data-sizes='small'] {
    --text-size-xxxlarge: 16px;
    --text-size-xxlarge: 15px;
    --text-size-xlarge: 14px;
    --text-size-large: 13px;
    --text-size-normal:12px;
    --text-size-small:11px;
    --text-size-xsmall:9px;
    --text-size-empty-data-warning:18px;
    --text-size-match-header:27px;
}

html[data-sizes='medium'] {
    --text-size-xxxlarge: 17px;
    --text-size-xxlarge: 16px;
    --text-size-xlarge: 15px;
    --text-size-large: 14px;
    --text-size-normal: 13px;
    --text-size-small:12px;
    --text-size-xsmall:11px;
    --text-size-empty-data-warning:20px;
}

html[data-sizes='large'] {
    --text-size-xxxlarge: 18px;
    --text-size-xxlarge: 17px;
    --text-size-xlarge: 16px;
    --text-size-large: 15px;
    --text-size-normal: 14px;
    --text-size-small:13px;
    --text-size-xsmall:12px;
    --text-size-empty-data-warning:20px;
}

html[data-sizes='xlarge'] {
    --text-size-xxxlarge: 19px;
    --text-size-xxlarge: 18px;
    --text-size-xlarge: 17px;
    --text-size-large: 16px;
    --text-size-normal: 15px;
    --text-size-small:14px;
    --text-size-xsmall:13px;
    --text-size-empty-data-warning:20px;
}