#page{
    margin:0 auto;
    overflow-x:clip;
}

.fadeIn{

}

.tabview{
    z-index:1;
}

.tabholder{
    z-index: 2;
    height:50px;
    width:100%;
    position: relative;
    border-bottom: 1px solid;
    /*#border-top: 1px solid;*/
    border-bottom-color: var(--tab-border-color);
    /*border-top-color: var(--tab-border-color-top);*/
    display: flex;
    flex-direction: row;
    /*will-change: transform*/
}

.tabholder2{
    z-index: 2;
    height:50px;
    width:100%;
    position: relative;
    border-bottom: 1px solid;
    /*border-top: 1px solid;*/
    border-bottom-color: var(--tab-border-color);
    border-top-color: var(--tab-border-color);
    display: grid;
    grid-auto-flow: column;
    /* gap: 2px; */
    height: 50px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    font-size: var(--text-size-normal);
    font-variant: all-small-caps;
}

.tabholder2::-webkit-scrollbar{
    display: none;
}

.tabholder2::-webkit-scrollbar {
    width: 0 !important
}

.tabholder-bg{
    height: 100%;
    width: 100%;
    position: absolute;
    /*border-bottom: 1px solid #203749;*/
    background: var(--tab-background-color);

    z-index: -1;
}

.tabviewindicator{
    height:4px;
    position:absolute;
    margin-top:44px;
    background-color: var(--tab-indicator-color);
    transition-duration:1000ms;
    border-radius: 5px;
}

.tabholder-title-container{
    margin-top:2px;
    height:48px;
    cursor:pointer;
    position:relative;
    background-color: transparent;
    border: none;
}

.tabholder-title-container2{
    height:48px;
    cursor:pointer;
    position:relative;
    scroll-snap-align: start;
    text-align: center;
    line-height: 19px;
    padding-top: 4px;
    background-color: var(--listHeaderBackground);
    z-index: -1;
}

.tabholder-title-container:not(:nth-child(3)):before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 5px;
    height: 40px;
    width: 51%;
    border-left: 1px solid var(--tabViewDividerBackground);
}

.tabholder-title-container .active{
    font-weight:600;
    color:var(--tab-text-color-selected);
}

.tabholder-title-container2 .active{
    font-weight:600;
    color:var(--tab-text-color-selected);
}

.tabholder-title-container:nth-child(2) {
    border-left: none;
}

.tabholder-title-container-bg{
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 123, 255, 0.41);;
    z-index: -1;
    opacity:0;
    transition: opacity 0.1s ease-in-out;
}

.tabholder-title-container-bg-touch{
    background-color: rgba(0, 123, 255, 0.41)!important;
    opacity:0.9;
}

.tabholder-title-container:focus{

}

.tabholder-title-container-bg-touch2{
    -webkit-animation-name: tabholder-title-container-bg-touch2;
    -webkit-animation-duration: 300ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
}

@-webkit-keyframes tabholder-title-container-bg-touch2 {
    0% {
        background-color: rgba(0, 255, 255, 0.49);
        opacity:0;
    }
    70% {
        background-color: #217985;
        opacity:0.3;
    }
    100% {
        opacity:0.8;
    }
}

.tabholder-title{
    margin-top: -4px;
    color:var(--tab-text-color);
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 16px;
    font-variant: all-small-caps;
    font-weight:500;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.tabContents{
    width:100%;
    transition-duration:1000ms;
    position:relative;
    overflow-y: visible;
}

.tabContentsScaleBack {
    animation-duration: 200ms;
    animation-name: tabstretchback;
    animation-fill-mode: forwards;
}

.tabContentsScaleBackX {
    animation-duration: 200ms;
    animation-name: tabstretchbackX;
    animation-fill-mode: forwards;
}

@keyframes tabstretchback {

    100% {
        transform: scaleY(1);
    }
}

@keyframes tabstretchbackX {

    100% {
        transform: scaleX(1);
    }
}

.tab-content-item{
    width:100%;
    position:absolute;
    /*border: 1px solid red;*/
}

.tabviewTabContent{
    width:100%;
    height:100%;
    position:relative;
    overflow-y: scroll;
    overflow-x:hidden;
    transform: translate(0,0);
}

