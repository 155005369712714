/*@keyframes headerCollapseAnimation {*/

/*    to {*/
/*        transform: translate(0, -50px);*/
/*    }*/
/*}*/

/*body {*/
/*    timeline-scope: --myScroller;*/
/*}*/

/*.animateHeaderCollapse {*/
/*    animation: headerCollapseAnimation linear both;*/
/*    animation-timeline: --myScroller;*/
/*    animation-range: 0px 50px;*/
/*}*/

/*.topBar{*/
/*    animation: headerCollapseAnimation linear both;*/
/*    animation-timeline: --myScroller;*/
/*    animation-range: 0px 50px;*/

/*}*/

/*.scrollerParent{*/
/*    height: -webkit-fill-available;*/
/*}*/

/*.enableCollapserTimeLine{*/
/*    scroll-timeline-name: --myScroller;*/
/*}*/

.listViewScroller{
    /*height: calc(100svh - 100px);*/
    height: calc(100svh - 50px);
    width:100%;

    overflow-y:scroll;
    overflow-x: hidden;
    margin-top:-50px;
    padding-top:50px;
    position: absolute;
    /*scroll-snap-type: y;*/
}

/*.listViewScroller > .LazyTileGrid {*/
/*    position: relative;*/
/*}*/

/*.listViewScrollerItem{*/
/*    width:100%;height:50px;margin:4px;background-color:var(--listItemBackground);*/
/*    scroll-snap-stop: normal;*/
/*    scroll-snap-align: center;*/
/*}*/