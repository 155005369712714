.tableInfoList{
    width: 100%;
    font-variant: none;
    font-size: var(--text-size-normal);
    text-align: center;
    border-spacing: 1px;
    direction: var(--direction);
}

.tableInfoTitle {
    padding-inline-end: 8px;
    padding-inline-start: 8px;
    text-align: end;
    vertical-align: top;
    font-variant: all-small-caps;
    font-size: var(--text-size-xlarge);
}

.tableInfoValue {
    width: 50%;
    text-align: start;
    font-weight: bold;
    vertical-align: top;
    font-variant: all-small-caps;
    font-size: var(--text-size-xlarge);
}

.tablePlayerStatsItemContainer{
    border-bottom: 1px solid var(--match-fixture-divider-color);
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 6px;
    margin-bottom: 2px;
    padding-inline-start: 4px;
}

.playerStatsStickyLegenda{
    margin-bottom:0px;
    top:0px;
    z-index:99999999;
    height:40px;
    position:sticky;
    padding-top:3px;

}

.playerInjuryStickyLegenda{
    margin-bottom:0px;
    top:0px;
    z-index:99999999;
    height:30px;
    position:sticky;
    padding-top:3px;
}

.playerStatsLegenda{
    background-color: var(--listHeaderBackground);
    height:100%;
    border-radius:8px;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
}

.playerStatsTable{
    width:100%;
    padding-top:4px;
    font-variant: none;
    font-size: var(--text-size-normal);

    text-align: center;
    border-spacing: 1px;
    direction: var(--direction);
}

.playerStatsTable th{
    border-spacing: 0px;
    font-size: var(--text-size-large);
    padding:4px;
    background-color: var(--listHeaderBackground);
    font-variant: all-small-caps;
}

.playerStatsTable tr{
    height:50px;
    cursor: pointer;
}

.tablePlayerStatsDivider{
    font-weight: bold;
}

.playerStatsItem{
    width:25px;
    text-align: center;
}

.playerStatsItemMinutes{
    width:50px;
    text-align: center;
}

.tableInjuries{
    border-spacing: 0px;
    border-radius:8px;
    padding:4px;
    width: 100%;
    background-color: var(--listHeaderBackground);
    font-size: var(--text-size-normal);
    direction: var(--direction);
}


.tableInjuries th{
    text-align: start;
    font-variant: small-caps;
    font-size: var(--text-size-large);
    border-collapse: collapse;
    height:20px;
}

.tableInjuries tr{
    width:100%;
    border-collapse: collapse;
}

.tableInjuries td{
    text-align: start;
    border-bottom: 1px solid var(--match-fixture-divider-color);
    font-weight: normal;
    height:40px;
}

.tableTransfers{
    border-spacing: 0px;
    border-radius:8px;
    padding:4px;
    width: 100%;
    background-color: var(--listHeaderBackground);
    font-size: var(--text-size-normal);
    direction: var(--direction);
}

.tableTransfers td{
    text-align: start;
    border-bottom: 1px solid var(--match-fixture-divider-color);
    font-weight: normal;
    height:40px;
}