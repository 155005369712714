.MatchPage{
    background-color: var(--match-background-color);
}

.MatchHeaderContainer{
    height:130px;
    width:100%;
    background-color:var(--match-header-background-color);
    color:var(--match-header-text-color);
    /*will-change: transform;*/
    z-index:2;
    position:relative;
    display:flex;
    flex-direction: var(--flex-direction);
}

.MatchHeaderBackgroundImage{
    width:100%;
    height:calc(100% + 50px);
    position: absolute;
    z-index: -1;
    opacity: var(--match-header-background-opacity);
    /*margin-top: -50px;*/
    transform: scaleX(1.2) translate(0px,-50px);
    transform-origin: top left;

}

.MatchHeaderBackgroundImage img{
    border:0;
}

.MatchHeaderHomeContainer{
    width: 36%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: space-around;
    align-items: center;
}

.MatchHeaderAwayContainer{
    width: 36%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: space-around;
    align-items: center;
}

.MatchHeaderStatusContainer{
    width: 28%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-content: flex-start;
    align-items: center;
    flex-direction: column;
    line-height: 28px;
    direction: var(--direction);
}

.MatchHeaderTeamLogoContainer{
    width: 80px;
    height: 80px;
}

.MatchHeaderTeamLogo{

}

.MatchHeaderTeamLogoPlaceHolder{

}

.MatchHeaderTeamText{
    font-size: var(--text-size-xxlarge);
    width: 100%;
    font-weight: bold;
    text-align: center;
    font-variant: all-petite-caps;
}

.MatchHeaderStatusDate{
    font-size: var(--text-size-xsmall);
    text-align: center;
}

.MatchHeaderStatusAggregate{
    font-size: var(--text-size-xlarge);
    font-size: var(--text-size-small);
    text-align: center;
    height: 17px;
}

.MatchHeaderStatusScoreTime{
    font-size: var(--text-size-match-header);
    padding:4px;
    border-radius: 10px;
}

.MatchHeaderStatusMatch{
    font-size: var(--text-size-xxlarge);
    text-align: center;
    font-variant: all-small-caps;
}

.ScoreTimeLarge{
    font-size: var(--text-size-xxxxlarge);
}

.scoretimeNormal{
    font-size: var(--text-size-xxlarge);
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}

.MatchHeaderAggregateWinnerColor{
    color:var(--text-color-matchstatus);
}

.MatchStatsContainer{
    box-sizing: border-box;
    padding:4px;
    margin-bottom:-10px;
    margin-top: 3px;


}

.MatchTitleContainerCard{
    background-color: var(--listHeaderBackground);
    width: 100%;
    border-radius: 10px;
    height: 100%;
    padding-bottom:10px;
    /*font-weight: bold;*/
}

.MatchStatsContainerCard{
    background-color: var(--listHeaderBackground);
    width: 100%;
    border-radius: 10px;
    height: 100%;
    padding-bottom: 6px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-content: center;*/
    /*align-items: center;*/
}
.MatchStatsTitleImage{
    height:20px;
    width:22px;
    margin-inline-end: 4px;
}

.MatchStatsTitleImage img{
    height:30px;
    width:30px;
}

.MatchStatsTitleTextTop{
    font-variant: small-caps;
    font-size: var(--text-size-large);
}
.MatchStatsTitleText{
    font-variant: all-small-caps;
    font-size: var(--text-size-xlarge);
    line-height: 20px;
    text-align: center;
    margin-bottom:4px;

}

.MatchStatsTitleTextBottom{
    font-size: var(--text-size-xsmall);
    font-variant: none;
    margin-top:6px;
    text-align: center;
}

/**
events
 */

.MatchEventContainer{
    height: 40px;
    margin-bottom: 12px;
    width: 100%;

    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: center;
    position: relative;
    direction: var(--direction);
}

.MatchEventsMinute{
    width: 10%;
    height: 40px;
    display: flex;
    flex-direction: column;
    margin: 4px;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: var(--text-size-xlarge);
    border-radius: 12px;
    /*border:1px solid #00ffbb61;*/
    border:1px solid var(--border-color-status);
}

.MatchEventVarContainer{
    font-size: 10px;
    background-color: white;
    position: absolute;
    color: black;
    padding: 1px;
    border-radius: 3px;
    bottom:0px;
}

.MatchStatsEventMinuteREsult{
    font-size:11px;
    color:var(--text-color-tertary);
}

.MatchStatsEventLocalTitleContainer{
    left: 0;
    position: absolute;
    width: 43%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: var(--flex-direction);
    background-color: var(--match-eventitem-background-color);
    border-radius: 8px;
}

.MatchStatsEventAwayTitleContainer{
    right: 0;
    position: absolute;
    width:43%;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: var(--flex-direction-inverse);
    background-color: var(--match-eventitem-background-color);
    border-radius: 8px;
}

.MatchStatsEventLocalPlayer{
    width:40px;
    height:40px;
    flex: none;
}

.MatchStatsEventAwayPlayer{
    width:40px;
    height:40px;
    flex: none;
}

.MatchStatsEventTypeImageContainer{
    width:24px;
    height:24px;
    margin: 3px;
}

.MatchStatsEventPlayerNameContainer{

    display:flex;
    flex-direction: column;
    line-height: 14px;
}

.MatchStatsEventPlayerName{

    word-break: break-word;
}
.MatchStatsEventPlayerAssist{

    word-break: break-word;
    font-size:10px;
}

.MatchStatsEventPlayerSubin{
    color:var(--text-color-match-green);
}

.MatchStatsEventPlayerSubout{
    color:var(--text-color-match-red);
}


.MatchComStatsContainer{
    width:100%;
    height:40px;
    padding-top: 10px;
}

/*.MatchComStatsContainer:first-child{*/
/*    padding-top:10px;*/
/*}*/

.MatchComStatsTitle{
    font-size: var(--text-size-normal);
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom:5px;
}

.MatchCommentary{
    font-size: var(--text-size-small);
}

.MatchComStatsBarContainer{
    width:100%;
    position: relative;
}

.MatchComStatsBarLocal{
    background-color:#40b5a3;
    height:12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
}

.MatchComStatsBarAway{
    background-color:#40b5a3;
    height:12px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
}

.MatchH2HTitle{
    text-align: center;
}

.MatchH2HMatchTitleContainer{
    height:20px;
    width:100%;
    display: flex;
    flex-direction: var(--flex-direction);;
    align-content: center;
    flex-wrap: nowrap;
    /*border-top: 1px solid var(--match-fixture-divider-color);*/
}

.MatchH2HMatchTitleContainerAlt{
    height:20px;
    width:100%;
    display: flex;
    flex-direction: var(--flex-direction);;
    align-content: center;
    flex-wrap: nowrap;
    /*border-top: 1px solid var(--match-fixture-divider-color);*/
}

.MatchH2HMatchTitleContainer p{
    padding:4px;
    font-size: var(--text-size-xsmall);
    color: var(--text-color-tertary);
}

.MatchH2HStatsContainer{
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-bottom:5px;
    direction: var(--direction);
}

.MatchVoteContainer{
    display: flex;
    flex-direction: var(--flex-direction);
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-bottom:10px;
}

.MatchVoteChartContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-bottom:2px;
    align-items: flex-end;
    height:80px;
    flex-direction: var(--flex-direction);
}

.MatchVoteButton{
    height:50px;
    background-color: var(--match-votebutton-background-color);
    border-radius: 10px;
    width:31%;
    margin-top:4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-variant: all-petite-caps;
    font-size: var(--text-size-xlarge);
    overflow-wrap: anywhere;
    color: var(--match-votebutton-text-color);
}

.MatchVoteChart{
    width:26%;
    font-size: var(--text-size-normal);
    background-color:var(--match-votebutton-background-color);
    transition: height 0.5s;
    height:1px;
}

.MatchVoteChartIsVotedByUser{
    background-color:var(--match-votebutton-selected-background-color);
}

.matchVoteResult{
    width:26%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-variant: all-petite-caps;
    font-size: var(--text-size-xlarge)
}

.MatchLineupPredictedTitle{
    height:25px;
    line-height:25px;
    border-radius:4px;
    margin:10px;
    width: calc(100% - 20px);
    text-align:center;
    background-color: #3a4372;
    color:var(--match-votebutton-text-color);
}

.MatchLineupPlayerContainer{
    margin-top:-10px;
    position: relative;
}

.MatchLineupPlayerRating{
    background-color:#344f82;
    width:23px;
    height:16px;
    position:absolute;
    top:22px;
    left:-17px;
    z-index:1000;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius:2px;
    font-size: var(--text-size-xsmall);
    color:black;
}

.MatchLineupPlayerRatingBad{
    background-color:#d32f2f;
}

.MatchLineupPlayerRatingOkaish{
    background-color:#f57c00;
}

.MatchLineupPlayerRatingAvarage{
    background-color: #acc53f;
}

.MatchLineupPlayerRatingGood{
    background-color:#388e3c;
}

.MatchLineupPlayerRatingExcellent{
    background-color: #da48d2;
}

.MatchLineupPlayerGoal{
    width:20px;
    height:20px;
    position:absolute;
    top:19px;
    right:-14px;
    z-index:1000;
    background-image: url("../images/football2.png");
    background-repeat: no-repeat;
    background-size: 20px 20px;

}

.matchLineupTitleBlockHome{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-left:17px;
    margin-right:17px;
    font-size: var(--text-size-large);
    color: var(--match-lineup-tshirt-color-home);
    margin-top:6px;
}

.matchLineupTitleBlockAway{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 12px;
    font-size: var(--text-size-xlarge);
    color: var(--match-lineup-tshirt-color-away);
    margin-top:-12px;
    margin-bottom: 16px;
}

.matchLineupTitleTeam{
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    font-variant: small-caps;
}

.matchLineupTitleFormation{
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
}

.MatchLineupBlockContainer{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.MatchLineupBlockTitle{
    text-align: center;
    height:20px;
    z-index:2;
    font-size: var(--text-size-small);
}

.MatchStatsContainerForm{}

.MatchStatsContainerFormStart{
    width:50%;
    justify-content: flex-start;
    margin-inline-end: 10px;
    margin-bottom:10px;
}
.MatchStatsContainerFormEnd{
    width:50%;
    justify-content: flex-end;
    margin-inline-start: 10px;
    margin-bottom:10px;
}

.MatchStatsContainerFormItem{
    width:6vw;
    height: 20px;
    margin:4px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center
}

.MatchStatsContainerFormWin{
    background-color: var(--match-stats-win-color);
}

.MatchStatsContainerFormDraw{
    background-color: var(--match-stats-draw-color);
}

.MatchStatsContainerFormLost{
    background-color: var(--match-stats-loss-color);
}

.tshirt-container{

}
.tshirt-container-flip-show-in{
    /*transform: rotateY(180deg);*/
    animation-duration: 1000ms;
    animation-name: playerFlip;
    animation-fill-mode: forwards;
}

.tshirt-container-flip-show-in:first-child{
    animation-duration: 1000ms;
    animation-name: playerFlip;
    /*animation-fill-mode: backwards;*/
    /*animation-direction: reverse;*/
}


.tshirt-container-flip-show-off{
    /*transform: rotateY(180deg);*/
    animation-duration: 1000ms;
    animation-name: playerFlip2;
    animation-fill-mode: forwards;
}

.MatchSubstitutionsContainer{
    margin-top: 12px;
    margin-bottom:12px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    direction: var(--direction);
}

.MatchSubstitutionsContainerLocal{
    left: 0;
    position: absolute;
    width: 43%;
    height: 80px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: var(--match-eventitem-background-color);
    border-radius: 4px;
}

.MatchSubstitutionsContainerAway{
    right: 0;
    position: absolute;
    width: 43%;
    height: 80px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: var(--match-eventitem-background-color);
    border-radius: 4px;
}

.MatchSubstitutionsPlayerLocalContainer{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.MatchSubstitutionsPlayerAwayContainer{
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between
}

.MatchSubstitutesContainer{
    display:flex;
    direction : var(--direction);
    justify-content: space-between;
}

.showMoreButton{
    margin: 0 auto;
    width:12px;
    height:12px;
    background-color: var(--match-votebutton-background-color);
    padding: 7px;
    margin-top:4px;
    margin-bottom:4px;
    text-align: center; cursor: pointer;
    border-radius:10px;
}

.shareButton{
    margin-top:10px;
    margin-bottom:4px;
    min-width:150px;
    height:40px;
    background-color: var(--match-votebutton-background-color);
    border-radius:4px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.playersidelinedicon{
    width: 10px;
    height: 10px;
    display:inline-block;
    position: absolute;
}
