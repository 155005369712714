html[data-textdirection='rtl'] {
   --flex-direction: row-reverse;
   --flex-direction-inverse: row;
   --text-align: right;
   --text-align-inverse: left;
   --direction : rtl;
   --margin-left-auto : inherit;
   --margin-right-auto : auto;
   --transform-origin : left;
}

html[data-textdirection='ltr'] {
    --flex-direction: row;
    --flex-direction-inverse: row-reverse;
    --text-align: left;
    --text-align-inverse: right;
    --direction : ltr;
    --margin-left-auto : auto;
    --margin-right-auto : inherit;
    --transform-origin : right;
}