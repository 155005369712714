.BDataGridTileListRender{
    /**
    do not put height 100%. dramatically decreases mobile scroll performance.
     */
}

.BDataGridTilePlaceHolder{
    overflow:hidden;
}

.LazyTileGrid{
    width: 100%;
}

.listViewMainContainer{
    height:100%;
}

.listViewScrollableContainer{
    width:100%;
    height:100%;
    position:relative;
    overflow-y: scroll;
    transform: translate(0,0);
    overflow-x:hidden;
}

@supports (-webkit-touch-callout: none) {
    .listViewScrollableContainer{
        overscroll-behavior: none;
    }
}

.scrollerNoOverscroll{
    overscroll-behavior: none;
}


.listViewScrollerBarContainer{
    position: absolute;
    height:100%;
    right:0;
    width:8px;
    background-color:var(--scrollbarContainer);
    z-index:2444;
}

.listViewScrollerIndicatorContainer{
    position: absolute;
    height: 100%;
    right:0px;
    z-index: 1;
    pointer-events: none;
}

.listViewFastScrollContainer{
    top:0;
    bottom:0;
    position:fixed;
    width:30px;
    z-index: 2;
}

.FastScrollContentItem{
    position: absolute;
    right:0px;
    font-size: var(--text-size-xxlarge);
    border-start-start-radius: 10px;
    padding-inline-start: 8px;
    padding-inline-end: 10px;
    padding-bottom:4px;
    min-width: 260px;
    min-height:50px;
    background-color: var(--fastScrollItemBackground);
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    z-index: 2445;
}

.FastScrollContentItemRTL{
    left:0;
}

.FastScrollContentItem label{
    padding-top:10px;
    padding-bottom:10px;
}

.FastScrollContentItem img{
    width:30px;
    height:30px;
    margin-inline-end: 6px;

}

.listViewScrollerBarItem{
    width:100%;
    height:30px;
    background-color: var(--scrollbarItem);
    z-index:2444;
}

.listViewEmptyData{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    font-size: var(--text-size-empty-data-warning);
    margin-top: -45px;
    color: var(--text-color-secundary);
    text-align: center;
    align-items: center;
}

.listViewScrollContainer{
    background-color:var(--listItemBackground);
    /*overflow-y:scroll;*/
    /*-webkit-overflow-scrolling: touch;*/
    /*height:100%;*/
    width:100%;
}

.listViewHeader{
    font-size:var(--text-size-xlarge);
    background-color:var(--listHeaderBackground);
    width:100%;
    color:var(--listHeaderTextColor);
    font-variant: all-small-caps;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    box-sizing:border-box;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display:flex;
    flex-direction: var(--flex-direction);
    border-bottom: 1px solid;
    border-bottom-color: var(--listHeaderBorderColor);
}

.standingsTeamName{
    font-weight: var(--listViewTextWeight);
}

.listViewItem{
    /*background-color:var(--listItemBackground);*/
    width:100%;
    color:var(--text-color);
    font-weight: var(--listViewTextWeight);
    border-bottom:1px solid;
    border-bottom-color:var(--listItemBorderColor);
    cursor:pointer;
    display: flex;
    flex-direction: var(--flex-direction);
}

.listViewAgenda{
    margin: 0 auto;
    display: grid;
    grid-auto-flow: column;
    /*gap: 2px;*/
    height: 50px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    background-color:var(--listHeaderBackground);
    font-size: var(--text-size-normal);
    font-variant: all-small-caps;
}

.listViewAgenda::-webkit-scrollbar{
    display: none;
}

.listViewAgenda::-webkit-scrollbar {
    width: 0 !important
}

.listViewAgenda > a {
    height:50px;

    scroll-snap-align: start;
    text-align: center;
    line-height: 16px;
    /*padding-top: 4px;*/
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.listViewItemGeneral{
    border-bottom:1px solid;
    border-bottom-color:var(--listItemBorderColor);
}

.listViewItemFavorites{
    width: 100%;
    color: var(--text-color);
    font-weight: var(--listViewTextWeight);
    cursor: pointer;
    border-bottom:1px solid;
    border-bottom-color:var(--listItemBorderColor);
    /*border-bottom: 1px solid #e7e7e7;*/
}

.listViewFavoriteIsLiveInfo{
    display: flex;
    font-variant: all-small-caps;
    font-size: var(--text-size-normal);
    padding:8px;
    padding-inline-end: 4px;
    text-align: start;
    line-height: 12px;
    font-weight: bold;
}

.listViewFavoriteIsLiveInfoStatus{
    font-variant: all-small-caps;
    font-size: var(--text-size-normal);
    color:#5cafb9;
}

.listViewFavoriteIsLiveInfoStatus.live{
    color : var(--text-color-matchstatus);
}

.isLiveTeamInfoTeamName {
    color : var(--text-color-quatory);
}

.listViewCountryFlagItem{
    float:left;
    width:40px;
    margin-inline-start: 4px;
    margin-top:6px;
    height: 100%;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: nowrap;*/
    /*align-content: center;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
}

.listViewCountryFlagItem img{
    width:40px;
}

.listViewFavoriteTeamItem{
    float:left;
    width:30px;
    margin-left:8px;
    margin-top:6px;
    box-sizing:border-box;
    flex-direction: var(--flex-direction);
}

.listViewFavoriteTeamItem img{
    width:30px;
}

.listViewCountryTitle{
    color: var(--text-color);
    font-size:var(--text-size-normal);
    float:left;
    height:100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-inline-start: 4px;
    font-weight: var(--listViewTextWeight);
    align-self: flex-end;
}

.listViewMatchHeader{
    display: flex;
    direction: var(--direction);
    font-size: var(--text-size-xlarge);
    background-color: var(--listHeaderBackground);
    width: 100%;
    color: var(--listHeaderTextColor);
    cursor: pointer;
    font-variant: all-small-caps;
    padding-inline-start: 4px;
    border-bottom: 1px solid;
    border-bottom-color: var(--listHeaderBorderColor);
    align-items: center;
    flex-direction: row;
}

.listViewHeaderMatchDateHeader{
    justify-content:space-between;
}

.listViewMatchHeader img{
}

.listViewMatchHeaderCountryImage{
    width:40px;
    height: 100%;
    margin-inline-end: 4px;
    flex: none;

}

.listViewMatchHeaderCountryImage img{
    width:40px;
    flex: none;
}

.listViewMatchHeaderCountryTitle{
    text-align: left;
    font-size: var(--text-size-xxlarge);

}

.listViewMatchHeaderFavoriteContainer{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
    margin-right:8px;
    margin-left: var(--margin-left-auto);
    margin-right: var(--margin-right-auto);
    cursor: none;
    margin-inline-end: 8px;
}

.listViewMatchHeaderFavoriteIcon{
    width: 25px;
    margin-top: 1px!important;
    opacity: 0.7;
}



.listViewMatchContentContainer{
    height:100%;
    /*background-color:blue;*/
    display: flex;
    flex-direction: var(--flex-direction);
    overflow: hidden;
    justify-content: center;
    width:100%;
}

.listViewMatchArrowContainer{
    float:right;
    width:14px;
    height:100%;
}

.listViewMatchArrow{
    width:12px;
    margin-top:17px;
}

.listViewMatchHomeContainer{
    display: flex;
    flex-direction: var(--flex-direction);
    justify-content: flex-end;
    height:100%;
    width:100%;
    overflow: hidden;
    flex: 1;
    flex-shrink: 0;
    /*background-color:blue;*/
    width:100%;
}

.listViewMatchAwayContainer{
    display: flex;
    flex-direction: var(--flex-direction);
    height:100%;
    overflow: hidden;
    flex: 1;
    flex-shrink: 0;
    word-break: break-all;
    width:100%;
}

.listViewMatchStatusContainer{
    width: 44px;
    float: left;
    display: flex;
    font-size:var(--text-size-normal);
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: wrap;
    direction: var(--direction);
}

.listViewMatchStatusScoreTime{
    text-align: center;
    width:45px;
    font-size:var(--text-size-large);
    font-weight: 400;
    direction: var(--direction);
}

.listViewMatchStatusMatch{
    text-align: center;
    width:45px;
    font-size:var(--text-size-normal);
    font-weight: bold;
    direction: var(--direction);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.listViewMatchStatusAggr{
    width:45px;
    letter-spacing: 1px;
    text-align: center;
    font-size:var(--text-size-xsmall);
    direction: var(--direction);
}

.listViewMatchStatusMatch.green{
    color:var(--text-color-matchstatus);
}

.listViewMatchStatusMatch.red{
    color: var(--text-color-match-red);
}

.listViewMatchStatusMatch.yellow{
    color: var(--text-color-match-yellow);
}

.listViewMatchHomeTitle{
    display: flex;
    margin-left:2px;
    margin-right:2px;
    color: var(--text-color);
    font-size:var(--text-size-normal);
    height:100%;
    width:100%;
    text-align: var(--text-align-inverse);
    word-break: break-word;
    /*background-color:yellow;*/
    align-content: flex-end;
    flex-direction: var(--flex-direction);
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

.listViewMatchAwayTitle{
    display: flex;
    margin-left:2px;
    margin-right:2px;
    color: var(--text-color);
    text-overflow: ellipsis;
    word-break: break-word;
    font-size:var(--text-size-normal);
    width:100%;
    text-align: var(--text-align);
    /*background-color:yellow;*/
    align-content: flex-start;
    flex-direction: var(--flex-direction);
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.listViewMatchHomeTeamLogoContainer{
    display: flex;
    width:48px;
    /*background-color:pink;*/
    height:100%;
    align-content: space-between;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex: none;
    position: relative;
}

.listViewMatchAwayTeamLogoContainer{
    display: flex;
    width:48px;
    /*background-color:pink;*/
    height:100%;
    align-content: space-between;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex: none;
    position: relative;
}

.listViewMatchRedCardHome{
    position:absolute;
    height:15px;
    width:10px;
    top:2px;
    right:2px;
    background-color:#ba322c;
}

.listViewMatchRedCardAway{
    position:absolute;
    height:15px;
    width:10px;
    top:2px;
    left:2px;
    background-color:#ba322c;
}

.listViewMatchRedCardAlt{
    height:15px;
    width:10px;
    top:2px;
    right:2px;
    background-color:#ba322c;
}

.listViewMatchNotificationContainer{
    float:left;
    width:36px;
    height:100%;
    display: flex;
    height:100%;
    align-content: space-between;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    cursor:none;
}

.listViewMatchNotificationContainerAlt{
    margin-inline-end: 8px;
}

.listViewMatchNotificationBell{
    width:32px;
    height:32px;
}

.listViewMatchTeamLogoImage{
    margin-top:4px;
    width:38px;
    flex: none;
}
.listViewMatchTeamLogoPlaceHolder{
    width:38px;
}

/**
aleternative render for listview match
 */

.listViewMatchContentContainerAlt{
    height:100%;
    /*background-color:blue;*/
    display: flex;
    flex-direction: var(--flex-direction);
    overflow: hidden;
    justify-content: space-between;
    width:100%;
}

.listViewMatchLeft{
    display: flex;
    align-items: center;
    flex-direction: var(--flex-direction);
}

.listViewMatchRight{
    display: flex;
    align-items: center;
    margin-inline-end: 3px;
    flex-direction: var(--flex-direction);
}

.listViewMatchStatusContainerAlt{
    width: 42px;
    float: left;
    display: flex;
    font-size:var(--text-size-normal);
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: wrap;
    text-align: start;
    direction: var(--direction);
    margin-inline-end: 10px;
}

.listViewMatchImageContainerAlt{
    width: 30px;
    height: 100%;
    float: left;
    display: flex;
    font-size:var(--text-size-normal);
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: wrap;
    text-align: start;
    direction: var(--direction);
    margin-inline-end: 4px;
}

.listViewMatchTeamLogoContainerAlt{
    width:30px;
    height:30px;
    display: flex;
    align-items: center;
}

.listViewMatchTeamLogoContainerAlt img{
    width:28px;
    height:28px;
}

.listViewMatchStatusMatchAlt{
    font-size:var(--text-size-xxlarge);
    font-weight: bold;
    font-variant: all-small-caps;
    margin-inline-start:4px;
}

.listViewMatchTeamsContainer{
    display: flex;
    flex-direction: column;
    line-height: 24px;
    height:100%;
    justify-content: space-evenly;
}

.listViewMatchScoreContainer{
    display: flex;
    flex-direction: column;
    line-height: 24px;
    margin-inline-end: 4px;
    height:50px;
    justify-content: space-around
}

.listViewMatchScoreAggrPen{
    font-weight:normal;
    font-size:var(--text-size-xsmall);
    display:inline;
}

.listViewMatchTeamTitleAlt{
    text-align: start;
    direction: var(--direction);
}

.listViewTitleItem{
    font-weight: bold;
}

.teamSquadPlayerStatsText{
    font-size:var(--text-size-xsmall);
}

.teamTableStandingsHeader{
    border-top: 1px solid var(--match-fixture-divider-color);
}

.listViewTeamFavoriteStar{
    display:inline-block;width:10px;height:10px;margin-left:3px;
}

.suggestionsItemTitle{
    width:100%;
    text-align: center;
    font-variant: all-small-caps;
    /*margin-bottom: 10px;*/
    font-size: var(--text-size-xlarge);
    border-bottom: 1px solid #cbd8dc73;
    /*padding-bottom:4px;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    height:50px;
    cursor:pointer;
}

.listViewMatchSuggestions{
    margin: 0 auto;
    display: grid;
    grid-auto-flow: column;
    /*gap: 2px;*/
    height: 80px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    font-size: var(--text-size-normal);
    font-variant: all-small-caps;
}

.listViewMatchSuggestions::-webkit-scrollbar{
    display: none;
}

.listViewMatchSuggestions::-webkit-scrollbar {
    width: 0 !important
}

.listViewMatchSuggestions > div {
    height:80px;
    scroll-snap-align: start;
    text-align: center;
    line-height: 16px;
    /*padding-top: 4px;*/
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    padding-right: 1px;
    padding-top: 2px;
    padding-left: 1px;
}

.listViewMatchSuggestionsMatchItem{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: var(--listHeaderBackground);
}

.listViewMatchSuggestionsMatchItemTop{
    display:flex;
    padding-top:2px;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 55%;
}

.listViewMatchSuggestionsMatchItemMiddle{
    font-size: var(--text-size-xxxlarge);
}

.listViewMatchSuggestionsMatchItemBottom{
    font-size: var(--text-size-xlarge);
}
